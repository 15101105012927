import React, { Component } from "react";
import "../assets/css/ThankYouPage.css";

class ThankYouPage extends Component {
  handleDonateAgain = (e) => {
    e.preventDefault();

    this.props.updateHasDonated(false);
  }

  handleContinue = (e) => {
    e.preventDefault();
    window.location.href = "https://www.bigsisters.bc.ca/"; // Redirect to Big Sisters website
  }

  render() {
    return (
      <div className="thank-you-page">
        <div className="form-box">
          <form>
            <h3 style={{color: '#2dccd3'}}>Thank You Marley!</h3>

            <button className="btn btn-theme effect btn-md donate-again" type="submit" 
            onClick={this.handleDonateAgain}>
            Process Another Monthly Donation
            </button>



          </form>
        </div>
      </div>
    );
  }
}

export default ThankYouPage;
