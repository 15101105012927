import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './App.css';

import { Route, Routes } from "react-router-dom";
import VirtualTerminalComponent from './components/VirtualTerminalComponent'
import MarleyDonationComponent from './components/MarleyDonationComponent';
import LoginComponent from './components/LoginComponent';


function App() {

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<VirtualTerminalComponent />} />
        <Route path="/marley" element={<VirtualTerminalComponent />} />
        <Route path="/marley/monthly" element={<MarleyDonationComponent />} />
        <Route path="/nathania" element={<VirtualTerminalComponent />} />
        <Route path="/marni" element={<VirtualTerminalComponent />} />
        <Route path="/cindy" element={<VirtualTerminalComponent />} />
        <Route path="/chantelle" element={<VirtualTerminalComponent />} />
        <Route path="/login" element={<LoginComponent />} />
        <Route path="*" element={<VirtualTerminalComponent />} />
      </Routes>
    </div>
  );
}

export default App;
